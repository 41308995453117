<!-- 开关闸记录页面 -->
<template>
  <div class="box-card" style="text-align: left">
    <div>
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="停车场：">
          <el-select popper-class="my-select" clearable v-model="queryForm.ParprakingID" filterable default-first-option
            placeholder="请选择停车场" @change="onSubmit()">
            <el-option v-for="item in getAllParkingList(2)" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通道：">
          <el-input class="paltetail" v-model="queryForm.PrakingName" placeholder="请输入通道" @input="
            (e) => (queryForm.PrakingName = validNumberLetter(e))
          " @keyup.enter.native="onSubmit()"></el-input>
        </el-form-item>
        <el-form-item label="管理者：">
          <el-input class="paltetail" v-model="queryForm.create_name" placeholder="请输入管理者" @input="
            (e) => (queryForm.create_name = validNumberLetter(e))
          " @keyup.enter.native="onSubmit()"></el-input>
        </el-form-item>
        <el-form-item label="操作时间：">
          <el-date-picker size="small" v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
            start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="来源：">
          <el-select popper-class="my-select" clearable v-model="queryForm.device_audio_source" placeholder="请选择记录来源"
            @clear="queryForm.device_audio_source = null" @change="onSubmit()">
            <el-option v-for="item in deviceList" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
        tooltip-effect="dark" border stripe>
        <el-table-column label="停车场">
          <template slot-scope="scope">
            <span>{{
              scope.row.ParprakingName ? scope.row.ParprakingName : "/"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="parking_name" label="通道">
          <template slot-scope="scope">
            {{ scope.row.PrakingName ? scope.row.PrakingName : "/" }}
          </template>
        </el-table-column>
        <el-table-column prop="parking_name" label="管理者">
          <template slot-scope="scope">
            {{ scope.row.create_name ? scope.row.create_name : "/" }}
          </template>
        </el-table-column>
        <el-table-column prop="parking_name" label="开关闸类型">
          <template slot-scope="scope">
            {{ scope.row.device_switch_status === 1 ? '开闸' : '关闸' }}
          </template>
        </el-table-column>
        <el-table-column label="操作时间">
          <template slot-scope="scope">
            {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="来源类型">
          <template slot-scope="scope">
          <!-- <span>{{ getWayName("ExitTypeInPark", scope.row.device_audio_source) }}
              </span> -->
            <span>{{ scope.row.device_audio_source === 0 ? '全部' : scope.row.device_audio_source === 1 ? "Web" : 'APP' }}</span>
          </template>
        </el-table-column>
      <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookPic(scope.row)"></lbButton>
          </template>
          </el-table-column> -->
      </el-table>
      <!-- 页码 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="TotalCount" >
      </el-pagination>
    </div>
    <!-- 来电弹框 -->
    <el-card v-if="isCallCome" class="callCome" shadow="hover">
      <div slot="header" class="clearfix">
        <span>请求接通</span>
        <el-button style="float: right; padding: 3px 0; width: 20px; color: black;" type="text" @click="smallDialog()"><i
            class="el-icon-close"></i></el-button>
      </div>
      <ul>
        <li v-for="(item, index) in callingUpList" :key="index" class="showUpPick">
          <div>
            {{ item.parkname.length>12? item.parkname.slice(0,12)+'...':item.parkname}}
          </div>
          <div>
            <el-button @click="takeOff(item)" type="danger" size="small">挂 断</el-button>
            <el-button @click="pickUp(item)" type="success" size="small">接 听</el-button>
          </div>
        </li>
      </ul>
    </el-card>
    <div class="smallButton" v-if="isSmallDialog && !isCallCome" @click="isCallCome = true"></div>
    <!-- 来电铃声标签 -->
    <audio ref="videoPlayer" id="videoPlayer" :src="require('@/assets/music/commingCallmusice.mp3')" muted loop class="audioBox"></audio>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import plateNumberHeadList from '@/utils/plate_number_head'
import { obtainSwitchRecord, getAccessStatus, modifyAudioRequestStatus } from '@/api/ychjapi'
export default {
  data () {
    return {
      timeduan: '',
      plateNumberHeadList: plateNumberHeadList,
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        ParprakingID: '',
        plate_number_head: '',
        PrakingName: '',
        create_name: '',
        start_time: '',
        end_time: '',
        device_audio_source: '',
        ParprakingName: ''
      },
      // 表格数据
      tableData: [],
      TotalCount: null,
      // 是否展示详情对话框
      isVisibleDialog: false,
      details: {},
      deviceList: [
        { value: 0, name: '全部' },
        { value: 1, name: 'Web' },
        { value: 2, name: 'APP' }
      ],
      timeResInfinity: '', // 轮询定时器
      callingUpList: [], // 来电列表
      isCallCome: false, // 来电弹框
      isSmallDialog: false // 小图标显示

    }
  },
  created () {

  },
  mounted () {
    this.fnobtainSwitchRecord()
    if (sessionStorage.getItem('infiniteVideoList')) {
      this.fngetAccessStatus(JSON.parse(sessionStorage.getItem('infiniteVideoList')))
    }
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList']),
    ...mapMutations('menuList', ['setActiveHearderMenu'])
  },
  watch: {
    timeduan (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_timed = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_timed = ''
        this.onSubmit()
      }
    },
    callingUpList: {
      immediate: true,
      deep: true,
      handler (newV, oldV) {
        if (newV.length > 0) {
          if (!this.isSmallDialog) {
            this.isCallCome = true
          }
        } else {
          this.isCallCome = false
        }
      }
    },
    isCallCome: {
      handler (newV) {
        if (newV) {
          this.$nextTick(() => {
            this.$refs.videoPlayer.muted = false
            // this.$refs.videoPlayer.load()
            this.$refs.videoPlayer.play()
          })
        } else {
          this.$refs.videoPlayer.pause()
          this.$refs.videoPlayer.load()
        }
      }
    }
  },
  methods: {
    // 获取开关闸记录
    async fnobtainSwitchRecord () {
      console.log('this.queryForm', this.queryForm)
      const res = await obtainSwitchRecord(
        {
          ParprakingID: this.queryForm.ParprakingID,
          PageIndex: this.queryForm.PageIndex,
          PageSize: this.queryForm.PageSize,
          PrakingName: this.queryForm.PrakingName,
          create_name: this.queryForm.create_name,
          start_time: this.queryForm.start_time,
          end_time: this.queryForm.end_time,
          device_audio_source: this.queryForm.device_audio_source ? this.queryForm.device_audio_source : 0 // 0全部 1：WEB 2：APP
        }
      )
      this.tableData =
        res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.TotalCount =
        res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // querySearch (queryString, cb) {
    //   var restaurants = this.plateNumberHeadList
    //   // console.log(restaurants);
    //   var results = queryString
    //     ? restaurants.filter(this.createFilter(queryString))
    //     : restaurants
    //     // 调用 callback 返回建议列表的数据
    //     // console.log('results----', results);
    //   cb(results)
    // },
    // createFilter (queryString) {
    //   return (restaurant) => {
    //     // console.log(restaurant);
    //     return (
    //       restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) !==
    //         -1
    //     )
    //   }
    // },
    // handleSelect (item) {
    //   // console.log('item----', item);
    //   this.queryForm.plate_number_head = item.value
    //   this.onSubmit()
    // },
    // handleIconClick (ev) {
    //   // console.log(ev);
    //   this.queryForm.plate_number_head = ''
    //   this.onSubmit()
    // },
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnobtainSwitchRecord()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fnobtainSwitchRecord()
    },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fnobtainSwitchRecord()
    },
    // 轮询获取出入口状态
    fngetAccessStatus (checkList) {
      // console.log('checkList', checkList)
      this.timeResInfinity = setInterval(async () => {
        const checkVideoStatusList = []
        const typeOneList = []
        const res = await getAccessStatus(checkList)
        // console.log('轮询状态', res)
        res.Data.forEach(item => {
          checkVideoStatusList.push({
            audiovideoType: item.audiovideoType,
            parkname: item.parkname,
            receive_name: item.receive_name,
            sn: item.camerasn,
            type: 'flv',
            id: item.id,
            parprakingID: item.parprakingID,
            unique_identifier: item.unique_identifier
          })
        })
        // console.log('轮询状态筛选', checkVideoStatusList)
        // 轮循到来电则加入数组显示弹框
        checkVideoStatusList.forEach((element, index) => {
          if (element.audiovideoType === 1) {
            typeOneList.push(element)
          }
        })
        // console.log('typeOneList', typeOneList)
        if (typeOneList.length > 0) {
          this.callingUpList = typeOneList
        } else {
          this.isSmallDialog = false
          this.callingUpList = []
        }
      }, 500)
    },
    // 关闭弹框
    smallDialog () {
      this.isSmallDialog = true
      this.callingUpList = []
      this.isCallCome = false
    },
    // 前往接听
    pickUp (item) {
      this.$router.push({ path: '/home/ychjgzt', query: { id: item.id } })
      // this.setActiveHearderMenu('工作台')
    },
    // 挂断
    async takeOff (item) {
      const obj = {}
      obj.id = item.id
      obj.name = item.parkname
      obj.type = 3
      obj.parprakingID = item.parprakingID
      const res = await modifyAudioRequestStatus(obj)
    }

    // // 查看图片
    // lookPic (row) {
    //   console.log(row)
    //   this.details = row
    //   this.isVisibleDialog = true
    // }
    // // 导出数据
    // async pullFile () {
    //   const res = await getCaptureRecordList({
    //     PageSize: 0,
    //     PageIndex: 0,
    //     parking_id: this.queryForm.parking_id,
    //     plate_number_head: this.queryForm.plate_number_head,
    //     plate_number_tail: this.queryForm.plate_number_tail,
    //     go_in_time_start: this.queryForm.go_in_time_start,
    //     go_in_time_end: this.queryForm.go_in_time_end,
    //     exitTypeInPark: this.queryForm.exitTypeInPark
    //       ? this.queryForm.exitTypeInPark
    //       : 0
    //   })
    //   if (res && res.Code === 200) {
    //     this.exportFile(res.Data.DataList)
    //   }
    // },
    // // 导出
    // exportFile (tableData) {
    //   if (tableData.length <= 0) {
    //     this.$msg.warning('暂无数据！')
    //   } else {
    //     // 设置当前日期
    //     var name = this.$moment(new Date()).format('YYYY-MM-DD')
    //     // console.log(name);
    //     import('../../utils/Export2Excel').then((excel) => {
    //       const tHeader = ['车牌号', '停车场', '通行时间', '来源']
    //       const filterVal = [
    //         'plate_number',
    //         'parking_name',
    //         'go_time',
    //         'exit_type'
    //       ]
    //       const list = tableData
    //       const data = this.formatJson(filterVal, list)
    //       // console.log(data);
    //       excel.export_json_to_excel({
    //         header: tHeader, //  必填   导出数据的表头
    //         data, //  必填   导出具体数据
    //         filename: '路内停车场抓拍记录' + name, //  非必填   导出文件名
    //         autoWidth: true, //  非必填   单元格是否自动适应宽度
    //         bookType: 'xlsx' //  非必填   导出文件类型
    //       })
    //     })
    //   }
    // },
    // formatJson (filterVal, jsonData) {
    //   return jsonData.map((v) =>
    //     filterVal.map((j) => {
    //       var map = {}
    //       this.$set(map, j, v[j])
    //       return this.formatter(map, { property: j })
    //       // 为formatter专门为了码表渲染的组件
    //     })
    //   )
    // },
    // // 数据格式化
    // formatter (row, column) {
    //   // console.log(row);
    //   // console.log(column);
    //   switch (column.property) {
    //     case 'parking_name':
    //       return row[column.property] ? row[column.property] : '/'
    //     case 'go_time':
    //       return this.$moment(row.go_time).format('YYYY-MM-DD HH:mm:ss')
    //     case 'exit_type':
    //       return this.getWayName('ExitTypeInPark', row.exit_type)
    //     default:
    //       return row[column.property]
    //   }
    // }
  },
  beforeDestroy () {
    clearInterval(this.timeResInfinity)
  }
}
</script>
<style scoped lang="less">
.box-card {
  padding: 30px 20px;

  .smallButton {
    position: fixed;
    width: 100px;
    height: 100px;
    bottom: 10px;
    right: 10px;
    background-image: url('~@/assets/img/ychj/smallDialog.png');
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .callCome {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 400px;

    /deep/.el-card__header {
      padding: 10px;
    }

    .showUpPick {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }

  }
}

.query_form {
  /deep/ .el-autocomplete {
    width: 100px;

    .el-input.el-input--small {
      width: inherit !important;
    }
  }
}

.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}

.paltetail {
  width: 200px;
  margin-left: 2px;
}

.el-dialog__wrapper {
  /deep/ .el-dialog__body {
    padding: 10px 20px 20px;

    .in-out-image {
      width: 100%;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;

      // background-color: #ccc;
      .image-slot {
        width: 100%;
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #f5f7fa;
        color: #999;
      }

      .el-image__inner {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }

    .image-slot {
      width: 100%;
      height: 450px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #f5f7fa;
      color: #999;
    }

    .el-carousel {
      width: 100%;
      height: 450px;
      margin-bottom: 10px;

      .el-carousel__container {
        position: relative;
        height: 450px;
      }

      .el-carousel__button {
        background-color: #027dfc !important;
      }

      .el-carousel__indicators {
        bottom: 14px;
      }

      .el-carousel__indicator--horizontal {
        padding: 4px 4px;
      }
    }
  }
}</style>
